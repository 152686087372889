import { twMerge } from "tailwind-merge";

export interface Props
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "html"> {
  html: string | undefined | null;
}

const LexicalHTML: React.FC<Props> = ({ html, className, ...props }) => {
  return (
    <div
      {...props}
      className={twMerge("lexical", className)}
      dangerouslySetInnerHTML={{ __html: html ?? "" }}
    />
  );
};
export default LexicalHTML;
